$phi:1.618em;
// -- Vendors
$pref:(webkit, moz, ms, o);
// -- Fonts
$icon:'FontAwesome';
$font:var(--font-roboto), sans-serif; //DinPro -> Roboto
$font-bold:var(--font-roboto-bold), sans-serif;
$font-light:var(--font-roboto-light), sans-serif;
$font-medium: var(--font-roboto-medium), sans-serif;
$font-black: var(--font-roboto-black), sans-serif;
// -- Colors
$black:#222222;
$dark:#444444;
$grey:#666666;
$gray:#AFAFAF;
$mediumgray:#3C3C3C;
$darkgray:#1f1d1d;
$light:#DDDDDD;
$soft:#f2f2f2;
$snow: #f8f8f8;
$red: #DA291C;
$reddark: hsl(4, 77%, 38%);
$wine: #b52333;
$blue:#0097A9;
$sky:#cae5ea;
$green:#7ad535;
$blue2:#2999A9;
// --  Social
$facebook:#3b5998;
$twitter:#1da1f2;
$pinterest:#cb2027;
$youtube:#bb0000;
$instagram:#e95950;
$whatsapp:#2eb944;
$telegram:#2ba7dd;
// - Sprites
$sprite-menu:'/media/layout/sprite-menu.png';
$sprite-icons:'/media/layout/sprite-icons.png';
$menu-icons:'/media/layout/menu.png';
// - token
$char-w: 1ch;
$gap: .75*$char-w;
$n-char: 6;
$in-w: $n-char*($char-w + $gap);
